<template>
  <div class="details pb-12">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">
            Membership Details
          </h2>
          <v-btn
            v-show="!membership.is_complete"
            class="primary--text mr-5"
            text
            depressed
            :loading="form.$busy"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            text
            depressed
            @click="saveAndNext"
          >
            Next
            <v-icon> {{ icons.mdiArrowRight }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <div class="details-form">
          <v-checkbox
            v-model="form.has_free_trial"
            class="d-inline-block mr-12"
            label="Has Free Trial"
            color="primary"
          />
          <v-checkbox
            v-model="form.is_featured"
            class="d-inline-block mr-12"
            label="Is Featured"
            color="primary"
          />
          <v-checkbox
            v-model="form.can_upgrade"
            class="d-inline-block mr-12"
            label="Upgradeable"
            color="primary"
          />
          <v-checkbox
            v-model="form.can_downgrade"
            class="d-inline-block"
            label="Downgradable"
            color="primary"
          />
          <v-row class="details-form">
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="form.name"
                label="Membership Name"
                :error-messages="form.$getError('name')"
                outlined
                flat
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="form.description"
                label="Membership Description"
                auto-grow
                outlined
                flat
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.subscription_level"
                label="Membership Level"
                outlined
                flat
                hide-details="auto"
                :error-messages="form.$getError('subscription_level')"
                type="number"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="form.membership_access"
                :items="accesses"
                label="Membership Access"
                outlined
                flat
                hide-details="auto"
                multiple
                deletable-chips
                chips
                small-chips
                :error-messages="form.$getError('membership_access')"
                class="mb-6"
              />
              <v-row>
                <v-col cols="12" md="6" v-if="hasMembershipAccessOf(5)">
                  <v-autocomplete
                    :items="plans"
                    v-model="form[5]"
                    item-text="name"
                    item-value="id"
                    label="Program Plans"
                    outlined
                    flat
                    multiple
                    chips
                    small-chips
                    hide-details="auto"
                    deletable-chips
                    hide-selected
                    :error-messages="form.$getError('program_plans')"
                  ></v-autocomplete>
                  <v-checkbox
                    v-model="form.instant"
                    label="Make program/challenge default after purchase"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" v-if="hasMembershipAccessOf(6)">
                  <v-autocomplete
                    :items="plans"
                    v-model="form[6]"
                    item-text="name"
                    item-value="id"
                    label="Challenge Plans"
                    outlined
                    flat
                    multiple
                    chips
                    small-chips
                    hide-details="auto"
                    deletable-chips
                    hide-selected
                    :error-messages="form.$getError('challenge_plans')"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" md="6" v-if="hasMembershipAccessOf(7)">
                  <v-autocomplete
                    :items="plans"
                    v-model="form[7]"
                    item-text="name"
                    item-value="id"
                    label="Discounted Program Plans"
                    outlined
                    flat
                    multiple
                    chips
                    small-chips
                    hide-details="auto"
                    deletable-chips
                    hide-selected
                    :error-messages="form.$getError('program_plans_discount')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" v-if="hasMembershipAccessOf(8)">
                  <v-autocomplete
                    :items="plans"
                    v-model="form[8]"
                    item-text="name"
                    item-value="id"
                    label="Discounted Challenge Plans"
                    outlined
                    flat
                    multiple
                    chips
                    small-chips
                    hide-details="auto"
                    deletable-chips
                    hide-selected
                    :error-messages="form.$getError('challenge_plans_discount')"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" md="6" v-if="hasMembershipAccessOf(9)">
                  <v-autocomplete
                    :items="plans"
                    v-model="form[9]"
                    item-text="name"
                    item-value="id"
                    label="Excluded Program Plans"
                    outlined
                    flat
                    multiple
                    chips
                    small-chips
                    hide-details="auto"
                    deletable-chips
                    hide-selected
                    :error-messages="form.$getError('excluded_program_plans')"
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                ref="activeFromDialog"
                v-model="activeFromModal"
                :return-value.sync="form.active_from"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.active_from"
                    label="Active From"
                    :error-messages="form.$getError('active_from')"
                    hide-details="auto"
                    readonly
                    outlined
                    flat
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.active_from" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="activeFromModal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.activeFromDialog.save(form.active_from)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                ref="activeToDialog"
                v-model="activeToModal"
                :return-value.sync="form.active_to"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.active_to"
                    label="Active To"
                    :error-messages="form.$getError('active_to')"
                    hide-details="auto"
                    readonly
                    outlined
                    flat
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.active_to" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="activeToModal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.activeToDialog.save(form.active_to)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="form.features"
                label="Membership Features"
                hide-selected
                small-chips
                multiple
                outlined
                flat
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="form.tags"
                :hide-no-data="!search"
                :search-input.sync="search"
                label="Membership Tags"
                hide-selected
                small-chips
                multiple
                outlined
                flat
                hide-details="auto"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <span class="subheading">Create</span>
                    <v-chip class="mx-2" color="primary" small>
                      {{ search }}
                    </v-chip>
                    <span> as a new tag </span>
                    <span> . Press <kbd>enter</kbd> to create a new one </span>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                ref="focus"
                v-model="form.image_public_id"
                label="Image"
                :error-messages="form.$getError('image_public_id')"
                hide-details="auto"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Form from '@/utils/form'

const defaultFormData = {
  name: '',
  description: '',
  membership_access: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  instant: false,
  features: [],
  tags: [],
  is_featured: false,
  subscription_level: null,
  can_upgrade: false,
  can_downgrade: false,
  has_free_trial: false,
  active_from: null,
  active_to: null,
  image_public_id: '',
}

export default {
  name: 'Details',
  props: {
    id: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      form: new Form(defaultFormData),
      icons: {
        mdiArrowRight,
      },
      activeFromModal: false,
      activeToModal: false,
      accesses: [
        { value: 1, text: 'All Program Plans' },
        { value: 2, text: 'All Challenge Plans' },
        { value: 3, text: 'All Program Plans Discount' },
        { value: 4, text: 'All Challenge Plans Discount' },
        { value: 5, text: 'Specific Program Plans' },
        { value: 6, text: 'Specific Challenge Plans' },
        { value: 7, text: 'Specific Program Plans Discount' },
        { value: 8, text: 'Specific Challenge Plans Discount' },
        { value: 9, text: 'Exclude Program Plans' },
      ],
      plans: [],
      search: null,
    }
  },
  computed: {
    ...mapGetters({
      membership: 'memberships/getSelectedMembership',
    }),
  },
  methods: {
    ...mapActions({
      storeMembership: 'memberships/storeMembership',
      getMembership: 'memberships/getMembership',
      getPlans: 'plans/getPlans',
    }),
    ...mapMutations({
      setSelectedMembership: 'memberships/setSelectedMembership',
    }),
    async saveAndNext() {
      try {
        const membership = await this.storeMembership(this.form)
        this.setSelectedMembership(membership)
        this.$router.push({
          name: 'memberships.variants',
          params: { id: membership.id },
        })
      } catch ({ response }) {
        if (response && response.status === 422) {
          this.form.$setErrors(response.data.errors)
        }
      }
    },
    async saveForLater() {
      try {
        await this.storeMembership(this.form)
        this.$router.push({ name: 'memberships', query: { status: 'draft' } })
      } catch ({ response }) {
        if (response && response.status === 422) {
          this.form.$setErrors(response.data.errors)
        }
      }
    },
    hasMembershipAccessOf(access) {
      return this.form.membership_access.includes(access)
    },
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property]
        const curGroup = acc[key] ?? []

        return { ...acc, [key]: [...curGroup, obj] }
      }, {})
    },
  },
  async created() {
    this.plans = await this.getPlans()

    if (this.id) {
      const membership = { ...this.membership }

      const membershipAccess = this.groupBy(
        membership.subscription_access,
        'access_type'
      )
      membership.membership_access = Object.keys(membershipAccess)?.map((c) =>
        Number(c)
      )

      membership.instant = !!membership?.subscription_access.find(
        (access) => [5, 6].includes(access.access_type) && access.instant
      )

      const {
        5: program_plans = [],
        6: challenge_plans = [],
        7: program_plans_discount = [],
        8: challenge_plans_discount = [],
        9: excluded_program_plans = [],
      } = membershipAccess
      membership[5] = program_plans
        .map((plan) => plan.value)
        .map((c) => Number(c))
      membership[6] = challenge_plans
        .map((challenge) => challenge.value)
        .map((c) => Number(c))
      membership[7] = program_plans_discount
        .map((challenge) => challenge.value)
        .map((c) => Number(c))
      membership[8] = challenge_plans_discount
        .map((challenge) => challenge.value)
        .map((c) => Number(c))
      membership[9] = excluded_program_plans
        .map((challenge) => challenge.value)
        .map((c) => Number(c))

      this.form.$refresh(membership)
    }
  },
}
</script>
